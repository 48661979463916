import React from "react";

const TableRow = ({
  rowData,
  showProjectManagement,
  showBusinessProcessOptimization,
  showDimensions,
  showRespondents,
  showMaturityLevels,
  selectedRespondentTitleOption,
  selectedScoreType,
  getCellColor,
}) => {
  const { name, role, type, email, scores } = rowData[0];

  let scoreKey = "";

  if (selectedScoreType === "Actual") {
    scoreKey = "actual";
  } else {
    scoreKey = "ambition";
  }

  return (
    <>
      {(showRespondents ||
        type === "cumulativeEmployee" ||
        type === "cumulativeManager" ||
        type === "cumulativeTotal") && (
        <tr className={type}>
          {
            <td className="identity">
              {(() => {
                if (selectedRespondentTitleOption === "Name") {
                  return name;
                } else if (selectedRespondentTitleOption === "Email") {
                  return email;
                } else if (selectedRespondentTitleOption === "Role") {
                  return role;
                }
              })()}
            </td>
          }
          {showDimensions && showProjectManagement && (
            <td
              style={{
                backgroundColor: getCellColor(
                  scores[scoreKey].projectManagement.projectPlanning
                ),
              }}
            >
              {scores[scoreKey].projectManagement.projectPlanning}
            </td>
          )}
          {showDimensions && showProjectManagement && (
            <td
              style={{
                backgroundColor: getCellColor(
                  scores[scoreKey].projectManagement.resourceAllocation
                ),
              }}
            >
              {scores[scoreKey].projectManagement.resourceAllocation}
            </td>
          )}
          {showProjectManagement && (
            <td
              style={{
                backgroundColor: getCellColor(
                  scores[scoreKey].projectManagement.projectManagementScore
                ),
              }}
            >
              {scores[scoreKey].projectManagement.projectManagementScore}
            </td>
          )}
          {showMaturityLevels && showProjectManagement && (
            <td className="maturity-level">
              {scores[scoreKey].projectManagement.maturityLevel}
            </td>
          )}

          {showDimensions && showBusinessProcessOptimization && (
            <td
              style={{
                backgroundColor: getCellColor(
                  scores[scoreKey].businessProcessOptimization
                    .streamliningOperations
                ),
              }}
            >
              {
                scores[scoreKey].businessProcessOptimization
                  .streamliningOperations
              }
            </td>
          )}
          {showBusinessProcessOptimization && (
            <td
              style={{
                backgroundColor: getCellColor(
                  scores[scoreKey].businessProcessOptimization
                    .businessProcessOptimizationScore
                ),
              }}
            >
              {
                scores[scoreKey].businessProcessOptimization
                  .businessProcessOptimizationScore
              }
            </td>
          )}
          {showMaturityLevels && showBusinessProcessOptimization && (
            <td className="maturity-level">
              {scores[scoreKey].businessProcessOptimization.maturityLevel}
            </td>
          )}

          {
            <td
              style={{
                backgroundColor: getCellColor(
                  scores[scoreKey].questionnaireTotal.questionnaireTotalScore
                ),
              }}
            >
              {scores[scoreKey].questionnaireTotal.questionnaireTotalScore}
            </td>
          }
          {showMaturityLevels && (
            <td className="maturity-level">
              {scores[scoreKey].questionnaireTotal.maturityLevel}
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export default TableRow;
