import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchPlus,
  faSearchMinus,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";
import "./NavBar.scss";

const NavBar = ({
  showProjectManagement,
  showBusinessProcessOptimization,
  setShowProjectManagement,
  setShowBusinessProcessOptimization,
  showDimensions,
  showRespondents,
  showMaturityLevels,
  setShowDimensions,
  setShowRespondents,
  setShowMaturityLevels,
  selectedRespondentTitleOption,
  setSelectedRespondentTitleOption,
  zoomLevel,
  setZoomLevel,
  showTopicsDropdown,
  setShowTopicsDropdown,
  showViewDropdown,
  setShowViewDropdown,
  selectedScoreType,
  setSelectedScoreType,
}) => {
  // Handle Dropdowns Visibility
  const handleToggleTopicsDropdown = () => {
    if (showViewDropdown === true) {
      setShowViewDropdown(!showViewDropdown);
    }
    setShowTopicsDropdown(!showTopicsDropdown);
  };

  const handleToggleViewDropdown = () => {
    if (showTopicsDropdown === true) {
      setShowTopicsDropdown(!showTopicsDropdown);
    }
    setShowViewDropdown(!showViewDropdown);
  };

  // handle Topics menu Options Change

  const handleProjectManagementChange = () => {
    setShowProjectManagement(!showProjectManagement);
  };

  const handleBusinessProcessOptimizationChange = () => {
    setShowBusinessProcessOptimization(!showBusinessProcessOptimization);
  };

  const handleScoreTypeChange = (score) => {
    setSelectedScoreType(score);
  };

  // handle View menu Options Change

  const handleToggleDimensions = () => {
    setShowDimensions(!showDimensions);
  };

  const handleToggleRespondents = () => {
    setShowRespondents(!showRespondents);
  };

  const handleToggleMaturityLevels = () => {
    setShowMaturityLevels(!showMaturityLevels);
  };

  const handleRespondentTitleOptionChange = (e) => {
    setSelectedRespondentTitleOption(e.target.value);
  };

  // Other handlers

  const handleZoomIn = () => {
    setZoomLevel(Math.min(zoomLevel + 0.1, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel(Math.max(zoomLevel - 0.1, 0.5));
  };

  const handleZoomReset = () => {
    setZoomLevel(1);
  };

  return (
    <div className="navbar">
      <div className="navigation">
        <div className="navigation-item">
          <h3 onClick={handleToggleTopicsDropdown}>
            Topics{" "}
            <FontAwesomeIcon
              icon={showTopicsDropdown ? "caret-up" : "caret-down"}
            />
          </h3>

          {/* Dropdown panel for Topics */}
          {showTopicsDropdown && (
            <div className="dropdown-panel">
              <label>
                <input
                  type="checkbox"
                  checked={showProjectManagement}
                  onChange={() => handleProjectManagementChange()}
                />
                Project Management
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={showBusinessProcessOptimization}
                  onChange={() => handleBusinessProcessOptimizationChange()}
                />
                Business Process Optimization
              </label>
              <div>
                <h4>Select score</h4>
                <label>
                  <input
                    type="radio"
                    value="Actual"
                    checked={selectedScoreType === "Actual"}
                    onChange={() => handleScoreTypeChange("Actual")}
                  />
                  Actual
                </label>
                <label>
                  <input
                    type="radio"
                    value="Ambition"
                    checked={selectedScoreType === "Ambition"}
                    onChange={() => handleScoreTypeChange("Ambition")}
                  />
                  Ambition
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="navigation-item">
          <h3 onClick={handleToggleViewDropdown}>
            View{" "}
            <FontAwesomeIcon
              icon={showViewDropdown ? "caret-up" : "caret-down"}
            />
          </h3>

          {/* Dropdown panel for View */}
          {showViewDropdown && (
            <div className="dropdown-panel">
              <label>
                <input
                  type="checkbox"
                  checked={showDimensions}
                  onChange={handleToggleDimensions}
                />
                Show dimensions
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={showRespondents}
                  onChange={handleToggleRespondents}
                />
                Show Respondents
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={showMaturityLevels}
                  onChange={handleToggleMaturityLevels}
                />
                Show Maturity Levels
              </label>

              <h4>Show Respondent's...</h4>
              <label>
                <input
                  type="radio"
                  value="Name"
                  checked={selectedRespondentTitleOption === "Name"}
                  onChange={handleRespondentTitleOptionChange}
                />
                Name
              </label>
              <label>
                <input
                  type="radio"
                  value="Email"
                  checked={selectedRespondentTitleOption === "Email"}
                  onChange={handleRespondentTitleOptionChange}
                />
                Email
              </label>
              <label>
                <input
                  type="radio"
                  value="Role"
                  checked={selectedRespondentTitleOption === "Role"}
                  onChange={handleRespondentTitleOptionChange}
                />
                Role
              </label>
            </div>
          )}
        </div>
      </div>

      <div className="additional-options">
        <div className="legend-list">
          <dl>
            <span className="pr-legend pr-color-high"></span>
            <span className="ng-binding">scores a 6 or higher</span>
          </dl>
          <dl>
            <span className="pr-legend pr-color-middle"></span>
            <span className="ng-binding">scores from 3 to 6</span>
          </dl>
          <dl>
            <span className="pr-legend pr-color-lower"></span>
            <span className="ng-binding">scores a 3 or lower</span>
          </dl>
        </div>
        <div className="zoom-controls">
          <button onClick={handleZoomOut}>
            <FontAwesomeIcon icon={faSearchMinus} />
          </button>
          <button onClick={handleZoomIn}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </button>
          <button onClick={handleZoomReset}>
            <FontAwesomeIcon icon={faDesktop} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
