const tableData = [
  // Data for managers
  {
    name: "Manager 1",
    role: "Manager",
    type: "manager",
    email: "manager1@test.com",
    scores: {
      actual: {
        projectManagement: {
          projectPlanning: 3,
          resourceAllocation: 4,
          projectManagementScore: 5,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 4,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 5,
          maturityLevel: 2,
        },
      },
      ambition: {
        projectManagement: {
          projectPlanning: 8,
          resourceAllocation: 7,
          projectManagementScore: 9,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 6,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 6,
          maturityLevel: 2,
        },
      },
    },
  },

  // Data for employees
  {
    name: "Employee 1",
    role: "Employee",
    type: "employee",
    email: "employee1@test.com",
    scores: {
      actual: {
        projectManagement: {
          projectPlanning: 7,
          resourceAllocation: 4,
          projectManagementScore: 5,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 4,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 5,
          maturityLevel: 2,
        },
      },
      ambition: {
        projectManagement: {
          projectPlanning: 8,
          resourceAllocation: 7,
          projectManagementScore: 9,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 6,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 6,
          maturityLevel: 2,
        },
      },
    },
  },

  // Static row for cumulative scores of employees
  {
    name: "EMPLOYEE",
    role: "EMPLOYEE",
    type: "cumulativeEmployee",
    email: "EMPLOYEE",
    scores: {
      actual: {
        projectManagement: {
          projectPlanning: 3,
          resourceAllocation: 4,
          projectManagementScore: 5,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 4,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 5,
          maturityLevel: 2,
        },
      },
      ambition: {
        projectManagement: {
          projectPlanning: 8,
          resourceAllocation: 7,
          projectManagementScore: 9,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 6,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 6,
          maturityLevel: 2,
        },
      },
    },
  },

  // Static row for cumulative scores of managers
  {
    name: "MANAGER",
    role: "MANAGER",
    type: "cumulativeManager",
    email: "MANAGER",
    scores: {
      actual: {
        projectManagement: {
          projectPlanning: 3,
          resourceAllocation: 4,
          projectManagementScore: 5,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 4,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 5,
          maturityLevel: 2,
        },
      },
      ambition: {
        projectManagement: {
          projectPlanning: 8,
          resourceAllocation: 7,
          projectManagementScore: 9,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 6,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 6,
          maturityLevel: 2,
        },
      },
    },
  },

  // Cumulative for "Questionnaire total"
  {
    name: "Questionnaire Total",
    role: "Questionnaire Total",
    type: "cumulativeTotal",
    email: "Questionnaire Total",
    scores: {
      actual: {
        projectManagement: {
          projectPlanning: 3,
          resourceAllocation: 4,
          projectManagementScore: 5,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 4,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 5,
          maturityLevel: 2,
        },
      },
      ambition: {
        projectManagement: {
          projectPlanning: 8,
          resourceAllocation: 7,
          projectManagementScore: 9,
          maturityLevel: 3,
        },
        businessProcessOptimization: {
          streamliningOperations: 6,
          businessProcessOptimizationScore: 6,
          maturityLevel: 5,
        },
        questionnaireTotal: {
          questionnaireTotalScore: 6,
          maturityLevel: 2,
        },
      },
    },
  },
];

export default tableData;
