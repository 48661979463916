import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Initialize Font Awesome
library.add(fas);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
