import React from "react";
import TableRow from "./../TableRow/TableRow.js";
import "./Table.scss";
import tableData from "../../data/tableData.js";

const Table = ({
  showProjectManagement,
  showBusinessProcessOptimization,
  showDimensions,
  showRespondents,
  showMaturityLevels,
  selectedRespondentTitleOption,
  selectedScoreType,
  zoomLevel,
}) => {
  const allEmployees = tableData.filter((record) => record.type === "employee");
  const allManagers = tableData.filter((record) => record.type === "manager");
  const cumulativeManagers = tableData.filter(
    (record) => record.type === "cumulativeManager"
  );
  const cumulativeEmployees = tableData.filter(
    (record) => record.type === "cumulativeEmployee"
  );
  const cumulativeTotal = tableData.filter(
    (record) => record.type === "cumulativeTotal"
  );

  const allSortedRows = [
    allManagers,
    cumulativeManagers,
    allEmployees,
    cumulativeEmployees,
    cumulativeTotal,
  ];

  // Helper function to determine cell color based on the score
  const getCellColor = (score) => {
    if (score <= 3) return "red";
    if (score > 3 && score <= 6) return "orange";
    return "green";
  };

  return (
    <div className="data-wrapper">
      <div className="table-container" style={{ zoom: zoomLevel }}>
        <table>
          <thead>
            <tr>
              {<th></th>}
              {showDimensions && showProjectManagement && (
                <th>
                  <div className="rotate">Project Planning</div>
                </th>
              )}
              {showDimensions && showProjectManagement && (
                <th>
                  <div className="rotate">Resource Allocation</div>
                </th>
              )}
              {showProjectManagement && (
                <th className="cumulative">
                  <div className="rotate">Project Management</div>
                </th>
              )}
              {showProjectManagement && showMaturityLevels && (
                <th>
                  <div className="rotate"></div>
                </th>
              )}
              {showDimensions && showBusinessProcessOptimization && (
                <th>
                  <div className="rotate">Streamlining Operations</div>
                </th>
              )}
              {showBusinessProcessOptimization && (
                <th className="cumulative">
                  <div className="rotate">Business Process Optimization</div>
                </th>
              )}
              {showBusinessProcessOptimization && showMaturityLevels && (
                <th>
                  <div className="rotate"></div>
                </th>
              )}
              {
                <th className="totals">
                  <div className="rotate">Questionnaire total</div>
                </th>
              }

              {showMaturityLevels && (
                <th>
                  <div className="rotate"></div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {allSortedRows.map((row, index) => (
              <TableRow
                key={index}
                rowData={row}
                showProjectManagement={showProjectManagement}
                showBusinessProcessOptimization={
                  showBusinessProcessOptimization
                }
                showDimensions={showDimensions}
                showRespondents={showRespondents}
                showMaturityLevels={showMaturityLevels}
                selectedRespondentTitleOption={selectedRespondentTitleOption}
                selectedScoreType={selectedScoreType}
                getCellColor={getCellColor}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
