import React, { useState } from "react";
import NavBar from "./components/NavBar/NavBar.js";
import Table from "./components/Table/Table.js";
import "./App.scss";

const App = () => {
  const [showProjectManagement, setShowProjectManagement] = useState(true);
  const [showBusinessProcessOptimization, setShowBusinessProcessOptimization] =
    useState(true);
  const [showDimensions, setShowDimensions] = useState(true);
  const [showRespondents, setShowRespondents] = useState(true);
  const [showMaturityLevels, setShowMaturityLevels] = useState(true);
  const [selectedRespondentTitleOption, setSelectedRespondentTitleOption] =
    useState("Name");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [selectedScoreType, setSelectedScoreType] = useState("Actual");

  // State for controlling the visibility of dropdown panels in NavBar
  const [showTopicsDropdown, setShowTopicsDropdown] = useState(false);
  const [showViewDropdown, setShowViewDropdown] = useState(false);

  return (
    <div className="App">
      <NavBar
        showProjectManagement={showProjectManagement}
        showBusinessProcessOptimization={showBusinessProcessOptimization}
        setShowProjectManagement={setShowProjectManagement}
        setShowBusinessProcessOptimization={setShowBusinessProcessOptimization}
        showDimensions={showDimensions}
        showRespondents={showRespondents}
        showMaturityLevels={showMaturityLevels}
        setShowDimensions={setShowDimensions}
        setShowRespondents={setShowRespondents}
        setShowMaturityLevels={setShowMaturityLevels}
        selectedRespondentTitleOption={selectedRespondentTitleOption}
        setSelectedRespondentTitleOption={setSelectedRespondentTitleOption}
        zoomLevel={zoomLevel}
        setZoomLevel={setZoomLevel}
        showTopicsDropdown={showTopicsDropdown}
        setShowTopicsDropdown={setShowTopicsDropdown}
        showViewDropdown={showViewDropdown}
        setShowViewDropdown={setShowViewDropdown}
        selectedScoreType={selectedScoreType}
        setSelectedScoreType={setSelectedScoreType}
      />
      <Table
        showProjectManagement={showProjectManagement}
        showBusinessProcessOptimization={showBusinessProcessOptimization}
        showDimensions={showDimensions}
        showRespondents={showRespondents}
        showMaturityLevels={showMaturityLevels}
        selectedRespondentTitleOption={selectedRespondentTitleOption}
        selectedScoreType={selectedScoreType}
        zoomLevel={zoomLevel}
      />
    </div>
  );
};

export default App;
